import { render, staticRenderFns } from "./driverBind.vue?vue&type=template&id=a615a192&scoped=true"
import script from "./driverBind.vue?vue&type=script&lang=js"
export * from "./driverBind.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a615a192",
  null
  
)

export default component.exports